@import '../../styles/vars';

.assortment {
}

.title {
  margin-bottom: 35px;
  text-align: center;
}

.list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.item {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  margin-right: 30px;
  width: 31%;

  &:last-child {
    margin-right: 0;
  }
  &:nth-child(3n) {
    margin-right: 0;
  }
}

.more {
  display: flex;
  justify-content: center;
  span {
    font-size: 24px;

    color: $blue;
    cursor: pointer;
    border-bottom: 1px solid $blue;
    transition: $time;

    &:hover {
      color: $lightBlue;
      border-bottom: 1px solid $lightBlue;
      transition: $time;
    }
  }
}

.noMore {
  span {
    color: rgb(223, 223, 223);
    cursor: default;
    border-bottom: 1px solid rgb(223, 223, 223);
  }
}

/* 768px - 992px */
@media screen and (max-width: 992px) {
  .item {
    width: 46%;
    margin-right: 30px;

    &:nth-child(3n) {
      margin-right: 30px;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

/* 576px - 768px */
@media screen and (max-width: 768px) {
  .item {
    width: 100%;
    margin-right: 0;

    &:nth-child(3n) {
      margin-right: 0px;
    }
  }
}

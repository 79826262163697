@import '../../styles/vars';

.order {
}

.title {
}

.body {
  display: flex;
  justify-content: space-between;
}

.form {
  display: block;
  position: relative;
  width: 50%;
  max-width: 620px;
  margin-top: 95px;
  z-index: 1;

  label {
    display: block;
    position: relative;
    margin-bottom: 24px;
  }

  input {
    width: 100%;
    padding: 20px 29px;

    background: $lightBlue;
    border: 1px solid $blue;
    border-radius: 50px;

    &:focus {
      background: #fff;
    }
    &.error {
      background: #ffe3e3;
      color: $black;
    }
    &.correct {
      background: #fff;
    }
  }

  &.sending {
    input {
      background: rgb(236, 236, 236);
      border: 1px solid rgb(236, 236, 236);
    }
  }

  .ok {
    position: absolute;
    top: 50%;
    right: 0;
    margin-right: 30px;
    transform: translateY(-50%);
  }
}

.inputEmail {
  margin-bottom: 12px;
}

.CheckboxLabel {
  position: relative;
  margin-bottom: 65px;
}

.inputCheckbox {
  position: absolute;
  width: 1px;
  height: 1px;

  white-space: nowrap;

  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
}

.CheckboxLabel {
  cursor: pointer;
  max-width: 380px;
}

.checkboxWrapp {
  display: flex;
}

.checkbox {
  display: inline-block;
  width: 22px;
  height: 22px;
  min-width: 22px;
  min-height: 22px;
  margin-right: 14px;
  margin-top: 7px;

  background: #fff;
  border: 2px solid $blue;
}

.checked {
  max-width: 22px;
  max-height: 22px;
  min-width: 22px;
  min-height: 22px;
  margin-top: 7px;
  margin-right: 14px;
}

.checkBoxText {
  max-width: 335px;

  span {
    color: red;
    font-size: 20px;
  }
}

.errorCheckBoxText {
  color: red;
}

.errorMsg {
  position: absolute;
  top: -28px;
  left: 0;

  color: red;
}

.cake {
  position: relative;
  width: 50%;

  &__img {
    position: relative;
    margin-top: 0;
    width: 100%;
    max-width: 687px;
    max-height: 515px;
    z-index: 1;
  }

  &__bg {
    position: absolute;
    top: -250px;
    right: -828px;
    width: 1421px;
    height: 1540px;
    max-width: 1421px;
    z-index: 0;
    transform: rotate(10deg);
  }
}

/* 992px - 1200px */
@media screen and (max-width: 1200px) {
  .cake {
    &__bg {
      top: -300px;
    }
  }
}

/* 768px - 992px */
@media screen and (max-width: 992px) {
  .body {
    flex-direction: column;
    align-items: center;
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-top: 35px;

    label {
      width: 100%;
      max-height: auto;
    }

    button {
      margin: 0 auto;
    }
  }
  .CheckboxLabel {
    margin-bottom: 40px;
    max-width: 370px;
  }
  .cake {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 75px;
  }
}

/* 576px - 768px */
@media screen and (max-width: 768px) {
  .form {
    margin-top: 35px;
  }
}

/* 320px - 450px */
@media screen and (max-width: 450px) {
  .cake {
    &__bg {
      top: -158px;
      right: -460px;
      width: 845px;
      height: 916px;
      max-width: 845px;
    }
  }
}

.feedback {
  position: relative;
  height: 700px;
}

.title {
  margin-bottom: 65px;
  text-align: center;
}

.feedbackTestContainer {
  width: 100%;
  margin-top: 200px;
  display: flex;
}

.wrappFeedbackCard2 {
  width: 50%;
}

/* 768px - 992px */
@media screen and (max-width: 992px) {
  .feedback {
    height: 500px;
  }
}

/* 576px - 768px */
@media screen and (max-width: 768px) {
  .feedback {
    height: 600px;
  }
}

/* 450px - 576px */
@media screen and (max-width: 576px) {
  .feedback {
    height: 700px;
  }
}

/* 320px - 450px */
@media screen and (max-width: 450px) {
  .feedback {
    height: 900px;
  }
}

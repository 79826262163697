@import '../../../styles/vars';

.assortmentCard {
  display: flex;
  flex-direction: column;
  max-width: 490px;

  box-shadow: 6px 8px 65px rgba(0, 0, 0, 0.1);
  border-radius: 35px;
}

.top {
}

.body {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding: 40px;
}

.title {
  margin-bottom: 20px;
  font-family: $Amatic;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  text-align: center;
}

.text {
  flex: 1 1;
  max-width: 379px;
  margin-bottom: 20px;
  text-align: left;
}

.price {
  span {
    display: block;
    margin-bottom: 20px;

    text-align: center;
    font-weight: 500;
    font-size: 24px;
    color: $blue;
  }
}

.button {
  text-align: center;
}

/* 768px - 992px */
@media screen and (max-width: 992px) {
  .text {
    margin: 0 auto 20px auto;
    text-align: center;
  }
}

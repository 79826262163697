.about {
  position: relative;
}

.info {
  display: flex;
  justify-content: space-between;
}

.description {
  position: relative;
  max-width: 620px;
  width: 41%;
}

.strawberries {
  display: none;
  position: absolute;
  top: 32px;
  right: -45px;
  width: 100px;
  height: 124px;
}

.title {
  margin-bottom: 45px;
  text-align: start;
}

.text {
  display: block;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0px;
  }
}

.photos {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 59%;
  padding-left: 30px;

  img {
    max-width: 490px;
    max-height: 565px;
    width: 50%;
  }
}

.photoCake {
  position: absolute;
  top: 90px;
  left: 100px;
  z-index: 1;
}

.photoGirl {
  position: relative;
  z-index: 0;
}

/* 1200px - 1550px */
@media screen and (max-width: 1550px) {
}

/* 992px - 1200px */
@media screen and (max-width: 1200px) {
  .about {
    align-items: center;
  }
  .photos {
    width: 50%;
  }
  .description {
    width: 50%;
  }
  .photoCake {
    left: 68px;
  }
}

/* 768px - 992px */
@media screen and (max-width: 992px) {
  .about {
    width: 100%;
  }
  .textFirst {
    max-width: 670px;
  }
  .textSecond {
    max-width: 100%;
  }
  .photos {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding-left: 0;
  }
  .photoCake {
    position: static;
    margin-bottom: 30px;
  }
}

/* 576px - 768px */
@media screen and (max-width: 768px) {
  .description {
    width: 100%;
  }
  .title {
    text-align: center;
  }
  .strawberries {
    display: block;
    top: -66px;
  }
  .textFirst {
    max-width: 530px;
  }
  .photos {
    display: none;
  }
}

/* 320px - 576px */
@media screen and (max-width: 576px) {
  .textFirst {
    max-width: 350px;
  }
}

@import '../../styles/vars';

.nav {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  z-index: 9;

  &--header {
    z-index: 9;
  }
}

.logo {
  cursor: pointer;
}

.navMenu {
  display: flex;

  li {
    margin-right: 65px;

    &:last-child {
      margin-right: 0px;
    }

    a {
      padding: 0 8px 5px 8px;
      cursor: pointer;
      border: 1px solid transparent;
      transition: $time;

      &:hover {
        border-bottom: 1px solid $lightBlack;
        transition: $time;
      }
    }
  }
}

.callbackBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &--footer {
    display: flex;
  }
}

.phoneWrapp {
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  transition: $time;

  &:hover {
    border-bottom: 1px solid $lightBlack;
    transition: $time;
  }
}

.phone {
  margin-right: 10px;
}
.number {
  cursor: pointer;
}

.socialIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 23px;

  a {
    display: inherit;
    margin-right: 19px;

    &:last-child {
      margin-right: 0;
    }

    svg {
      * {
        transition: $time;
      }
      &:hover * {
        fill: #8fc4f1;
        transition: $time;
      }
    }
  }
}

.alert {
  font-size: 12px;
  text-decoration: underline;

  cursor: pointer;
  transition: $time;

  &:hover {
    transition: $time;
    color: #a8a8a8;
  }
}

.hamburger {
  display: none;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 21px;
}

.hamburgerActive {
  .centerLine {
    background: transparent;

    &::before {
      top: 50%;
      margin-top: -1.5px;

      transform: rotate(45deg);
      transition: $time;
    }
    &::after {
      bottom: 50%;
      margin-bottom: -1.5px;

      transform: rotate(-45deg);
      transition: $time;
    }
  }
}

.centerLine {
  width: 100%;
  height: 3px;

  background: $black;
  transition: $time;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;

    background: $black;
    transition: $time;
  }

  &::before {
    top: 0;
    left: 0;
  }
  &::after {
    bottom: 0;
    left: 0;
  }
}

/* 992px - 1200px */
@media screen and (max-width: 1200px) {
  .navMenu {
    li {
      margin-right: 10px;
    }
  }
}

/* 768px - 992px */
@media screen and (max-width: 992px) {
  .nav--footer {
    height: 100%;
    flex-direction: column;
    align-items: center;
  }
  .navMenu {
    position: absolute;
    top: 100px;
    right: 0;
    flex-direction: column;
    align-items: flex-end;
    padding: 15px;

    background: #ffffff;
    border: 1px solid $lightBlack;
    z-index: 5;
    opacity: 0;
    visibility: hidden;
    transition: $time;

    li {
      margin-right: 0px;
      list-style: inside;
      opacity: 0;
      transition: $time;
    }
  }
  .navMenuActive {
    opacity: 1;
    visibility: visible;
    transition: $time;
    li {
      opacity: 1;
      transition: $time;

      &:hover {
        border-bottom: 1px solid transparent;
      }
    }
  }
  .number {
    margin-top: 10px;
  }
  .hamburger {
    display: flex;
  }
  .callbackBlock--header {
    display: none;
  }
  .socialIcons {
    margin-top: 15px;
  }
}

@import '../../styles/vars';

.footer {
  position: relative;
  padding-bottom: 12px;

  nav {
    align-items: flex-start;
  }
}

.copyright {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  z-index: 7;

  a {
    font-size: 16px;
    font-weight: 400;

    border: 1px solid transparent;
    transition: $time;

    &:hover {
      border-bottom: 1px solid $lightBlack;
      transition: $time;
    }

    &:first-of-type {
      margin-right: 30px;
    }
  }
}

.icon {
  margin-right: 18px;
  margin-top: 2px;
  font-size: 16px;
}

/* 768px - 992px */
@media screen and (max-width: 992px) {
  .footer {
    nav {
      align-items: center;
    }
    .text {
      font-size: 14px;
    }
    .textDev {
      font-size: 14px;
    }
  }
}

/* 320px - 576px */
@media screen and (max-width: 576px) {
  .links {
    display: flex;
    flex-direction: column;
  }
}

@import '../../../styles/vars';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;

  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transition: $time;

  &--show {
    opacity: 1;
    visibility: visible;
    transition: $time;
  }
}

.modal {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 500px;
  height: 90vh;
  padding: 50px;

  background: #fff;
  box-shadow: 0px 0px 17px 7px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;

  cursor: pointer;
  transition: $time;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;

    transform: rotate(45deg);
    background: $blue;
    transition: $time;
  }

  &::after {
    transform: rotate(-45deg);
  }

  &:hover {
    border: 1px solid $lightBlue;

    &::before,
    &::after {
      background: $lightBlue;
    }
  }
}

.ok {
  width: 150px;
  height: 150px;
  margin-bottom: 34px;
}

.title {
  margin-bottom: 15px;
}

.text {
  text-align: center;
}

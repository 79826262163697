@import './vars';

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  min-width: 320px;

  font-family: $mainFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  color: $black;
  font-size: $mainFontSize;
  line-height: 170%;

  background-color: #fff;
}

body *,
body *:before,
body *:after {
  box-sizing: border-box;
}

div,
textarea,
p,
input,
form,
span,
ul,
li {
  margin: 0;
  padding: 0;

  font-family: $mainFont;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;

  font-family: $mainFont;
  text-transform: uppercase;
}

h1 {
  display: block;
  font-family: $Amatic;
  font-size: 70px;
  font-weight: 700;
  line-height: 88px;
}
h2 {
  font-family: $Amatic;
  font-size: 64px;
  font-weight: 700;
  text-align: center;
  line-height: 140%;
}
h3 {
  font-size: 24px;
  font-weight: 600;
}

address,
blockquote,
figure,
figcaption,
main,
details,
fieldset {
  display: block;
  border: 0;
  margin: 0;
  padding: 0;
  font-style: normal;
}

a {
  margin: 0;
  padding: 0;

  list-style-type: none;
  text-decoration: none;

  box-sizing: border-box;
  color: inherit;
  border: none;
  outline: none !important;
  transition: all 0.2s ease-in-out;
  &:hover,
  &:active {
    text-decoration: none;
    outline: none;
    transition: all 0.2s ease-in-out;
  }
}

button,
input {
  display: block;
  margin: 0;
  padding: 0;

  list-style-type: none;
  box-sizing: border-box;
  outline: none;
  transition: all 0.2s ease-in-out;
  &:active,
  &:hover,
  &:focus {
    outline: none;
    transition: all 0.2s ease-in-out;
  }
}

input,
li {
  list-style-type: none;
}

.App {
  position: relative;
  overflow: hidden;
}

.container {
  max-width: 1650px;
  margin: 0 auto;
  padding: 0 60px;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

.advantages {
  margin-top: 450px;
}

.about {
  margin-top: 200px;
}

.assortment {
  margin-top: 200px;
}

.assortment-container {
  position: relative;

  .berriesBg {
    position: absolute;
    top: -186px;
    left: -100px;
  }
}

.feedback {
  margin-top: 200px;
}

.feedback-container {
  position: relative;

  .strawberries {
    position: absolute;
    right: -100px;
    top: -187px;
  }
  .berriesBg {
    display: none;
    position: absolute;
    top: -186px;
    left: -100px;
  }
}

.order {
  margin-top: 150px;
}

.order-container {
  position: relative;
  z-index: 1;
}

.footer-container {
  position: relative;
  z-index: 0;
}

.footer {
  position: relative;
  z-index: 1;
  margin-top: 155px;
}

/*******************************************************************/
/******************  Стили для слайдера БАЗОВЫЕ  *******************/
/*******************************************************************/

/* Слайдер *БАЗА СТИЛЕЙ* */
.slick-slider {
  margin: 0 auto;
  width: 100%;

  // border: 1px solid red;
}
/* Слайдер запущен */
.slick-slider.slick-initialized {
}
/* Слайдер с точками */
.slick-slider.slick-dotted {
}
/* Ограничивающая оболочка */
.slick-list {
  height: 100%;
  margin: 0 auto;
  border-radius: 35px;
  width: 80%;
  overflow: visible;

  // border: 1px solid rgb(81, 255, 0);
}
/* Лента слайдов */
.slick-track {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: center;
  height: 100%;
  will-change: transform;

  // border: 1px solid red;
}

/* Слайд */
.slick-slide {
  display: flex !important;
  height: auto;
  opacity: 0;
  visibility: hidden;
  transition: $time;
  outline: none !important; /* отключение обводки на слайде при клике */
  will-change: transform;

  // border: 1px solid rgb(255, 0, 0);

  & > div {
    display: flex;
    padding: 0 15px;
    &:focus-visible {
      outline: none;
    }
  }
}

/* Слайд активный (показывается) */
.slick-slide.slick-active {
  opacity: 1;
  visibility: visible;
  transition: $time;
  will-change: transform;
}
/* Слайд основной */
.slick-slide.slick-current {
}
/* Слайд по центру */
.slick-slide.slick-center {
}
/* Клонированный слайд */
.slick-slide.slick-cloned {
  will-change: transform;
}
/* Стрелка */
.slick-arrow {
  width: 20px;
  height: 38px;

  &::before,
  &::after {
    visibility: hidden;
  }
}
/* Стрелка влево */
.slick-arrow.slick-prev {
  left: 0;
  background: url(../img/prev-arrow.svg) no-repeat center;
  z-index: 1;
}
/* Стрелка вправо */
.slick-arrow.slick-next {
  right: 0;
  background: url(../img/next-arrow.svg) no-repeat center;
}
/* Стрелка не активная */
.slick-arrow.slick-disabled {
}
/* Точки (булиты) */
.slick-dots {
  bottom: -45px;
}
.slick-dots li {
}
/* Активная точка */
.slick-dots li.slick-active {
  button {
    &::before {
      background: $blue;
      border: 1px solid $blue;
    }
  }
}
/* Элемент точки */
.slick-dots li button {
  &::before {
    content: '';
    border: 1px solid $blue;
    border-radius: 50%;
    width: 9px;
    height: 9px;
  }
}

.test {
  margin-top: 1500px;
}
.link {
  cursor: pointer;
  color: red;
}

/*Responsive*/
/* 1200px - 1550px */
@media screen and (max-width: 1550px) {
  .slick-list {
    width: 90%;
  }
  .slick-slide {
    & > div {
      width: 98% !important;
    }
  }
  .slick-slide.slick-active {
    justify-content: center;
  }
}

/* 768px - 992px */
@media screen and (max-width: 992px) {
  .container {
    padding: 0 40px;
  }
  .advantages {
    margin-top: 150px;
  }
  .about {
    margin-top: 100px;
  }
  .assortment {
    margin-top: 100px;
  }
  .order {
    margin-top: 100px;
  }
  .assortment-container {
    .berriesBg {
      display: none;
    }
  }
  .feedback-container {
    position: relative;

    .strawberries {
      display: none;
    }
    .berriesBg {
      display: block;
      left: -47px;
      width: 116px;
      height: 134px;
    }
  }
  .slick-slider {
    width: 80%;
  }
  .slick-list {
    width: 100%;
    display: block;
  }
  .slick-track {
    display: block;
    height: auto;
  }
  .slick-slide {
    display: block !important;
    height: 100%;

    & > div {
      display: block;
      padding: 0;
      width: 100% !important;
    }
  }
  .footer {
    margin-top: 80px;
  }
}

/* 576px - 768px */
@media screen and (max-width: 768px) {
  .container {
    padding: 0 20px;
  }
  .slick-slider {
    width: 100%;
  }
}

/* 450px - 576px */
@media screen and (max-width: 576px) {
}

/* 320px - 450px */
@media screen and (max-width: 450px) {
}

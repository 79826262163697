@import '../../styles/vars';

.header {
  padding-top: 30px;
}

.blueberries21Top {
  display: none;
}
.blueberries22Top {
  display: none;
}
.blueberries23Top {
  display: none;
}
.srawberries {
  display: none;
}

.offer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 70px;
  z-index: 2;
}

.inner {
  width: 45%;
}

.blotterInner {
  display: none;
}

.title {
  position: relative;
  max-width: 683px;
  margin-bottom: 10px;

  font-family: $Amatic;
  z-index: 2;
}

.text {
  position: relative;
  max-width: 490px;
  margin-bottom: 65px;

  z-index: 2;
}

.innerBtn {
  position: relative;
  z-index: 2;
}

.cakeWrapp {
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 55%;
}

.cake {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;

  z-index: 1;
}

.firstOrbite {
  width: 80%;
  height: 0;
  padding-bottom: 80%;
  margin-top: -40%;
  margin-left: -40%;

  animation: spin-right 50s linear infinite;
  -webkit-animation: spin-right 50s linear infinite;

  img {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 25%;
    left: 93%;
    width: 64.68px;
    height: 55.73px;
  }
}

.secondOrbite {
  width: 91%;
  height: 0;
  padding-bottom: 91%;
  margin-top: -45.5%;
  margin-left: -45.5%;

  animation: spin-right 60s linear infinite;
  -webkit-animation: spin-right 60s linear infinite;
}

.firstOrbite,
.secondOrbite {
  position: absolute;
  top: 50%;
  left: 50%;

  border: 1px solid #97ceff;
  border-radius: 50%;
  z-index: 1;
}

.blueberries21,
.blueberries22,
.blueberries23 {
  transform: translate(-50%, -50%);
  position: absolute;
}

.blueberries21 {
  top: 0%;
  left: 50%;
}

.blueberries22 {
  top: 76%;
  left: 93%;
}

.blueberries23 {
  top: 73%;
  left: 5%;
}

.lavanda1 {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 17%;
  left: 13%;
}

.lavanda2 {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 93%;
  left: 71%;
}

.bigCake {
  max-width: 655px;
  max-height: 655px;
  width: 70%;
  margin: 0 auto;
  z-index: 1;
}

.blotter {
  position: absolute;
  top: -737px;
  right: -518px;
  width: 1421px;
  height: 1540px;
  max-width: 1421px;
  z-index: 0;
}

@keyframes spin-right {
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin-right {
  100% {
    transform: rotate(360deg);
  }
}

/* 1550px - 1700px */
@media screen and (max-width: 1700px) {
}

/* 1200px - 1550px */
@media screen and (max-width: 1550px) {
  .title {
    max-width: 600px;
  }
  .text {
    max-width: 380px;
  }
}

/* 768px - 992px */
@media screen and (max-width: 992px) {
  .header {
    position: relative;
  }
  .offer {
    justify-content: center;
  }
  .blueberries21Top {
    display: block;
    position: absolute;
    top: 12%;
    right: 29%;
    width: 43px;
    height: 37px;

    transform: rotate(155deg);
    z-index: 3;
  }
  .blueberries22Top {
    display: block;
    position: absolute;
    top: 34%;
    right: 3%;
    width: 59px;
    height: 50px;

    z-index: 3;
    transform: rotate(-96deg);
  }
  .blueberries23Top {
    display: block;
    position: absolute;
    top: 51%;
    right: 11%;
    width: 35px;
    height: 30px;

    z-index: 3;
    transform: rotate(-110deg);
  }
  .srawberries {
    display: block;
    position: absolute;
    top: 62%;
    right: -5%;
    width: 100px;
    height: 124px;
    z-index: 3;
  }
  .inner {
    width: 100%;
  }
  .blotterInner {
    display: block;
    position: absolute;
    top: -361px;
    right: -468px;
    width: 711px;
    height: 770px;
    max-width: 711px;
    z-index: 0;
  }
  .cakeWrapp {
    display: none;
  }
  .blotter {
    width: 488px;
    height: 529px;
    max-width: 488px;
  }
  .inner {
    max-width: 500px;
    text-align: center;
  }
  .title {
    margin-bottom: 0;
  }
  .text {
    margin: 20px auto 30px auto;
  }
}

/* 450px - 576px */
@media screen and (max-width: 576px) {
  .inner {
    max-width: 296px;
  }
  .text {
    max-width: 490px;
    margin-bottom: 40px;
  }
  .blueberries21Top {
    top: 8%;
    right: 29%;
  }
  .blueberries22Top {
    top: 15%;
    right: 3%;
  }
  .blueberries23Top {
    top: 30%;
    right: 11%;
  }
  .srawberries {
    top: 38%;
    right: -5%;
  }
}

/* 320px - 450px */
@media screen and (max-width: 450px) {
  .offer {
    justify-content: flex-start;
  }
  .inner {
    text-align: left;
  }
  .blotterInner {
    top: -261px;
    right: -308px;
    width: 488.62px;
    height: 529.62px;
    max-width: 488.62px;
  }
}

@import '../../../styles/vars';

.btn {
  position: relative;
  display: inline-block;
  padding: 18px 60px;
  max-width: 183px;
  max-height: 66px;
  min-width: 183px;
  min-height: 66px;

  font-size: 24px;
  font-family: $Amatic;
  text-transform: uppercase;

  color: #fff;
  background: #5b9ed9;
  box-shadow: 3px 6px 0px #487dac;
  border-radius: 50px;
  border: 0;
  cursor: pointer;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    background: #9ac4ea;
    box-shadow: 3px 6px 0px #739fc5;
    transition: $time;
  }

  &:active {
    background: #ffffff;
    border: 1px solid #5b9ed9;
    box-shadow: 3px 6px 0px #739fc5;
    color: $blue;
    transition: all 0.01s ease-in-out;
  }
}

.btn--sending {
  background: #dfe9f2;

  &:hover {
    background: #dfe9f2;
    box-shadow: 3px 6px 0px #487dac;
  }
}
